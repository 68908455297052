<template>
	<div>
		<div class="nav4" style="margin-top: 400px;">
			<div class="liuff" style="margin-top: -100px;">
				<div style="width: 80%; margin: auto; height: 320px;position: relative;">
					<div style="position: absolute;top: -300px;width: 60%;">
						<img width="500px" src="../../assets/all/金头.jpeg">
					</div>
					<div style="color: #fff;text-align: right;">
						<div style="font-size: 18px;">业务范围</div>
						<div style="width: 50px;border:0.5px solid #bfbec9;float: right;margin-right: 0px;"></div>
					</div>
					
				</div>
				<div style="margin: auto;border: 1px solid #fff;height:400px;width: 60%;margin-top: 0px;">
					<div style="text-align:center; color:#fff;font-size: 20px;line-height: 370px;">文案待定</div>
				</div>
				<div style="text-align: center;margin-top: 70px;font-size: 20px;">
					<router-link style="color: #ced0d5;" to="##">查看更多</router-link>
					
				</div>
			</div>
			
		</div>
		
	</div>
</template>

<script>
	export default {
		data(){
			return {
				
			}
		}
	}
</script>

<style scoped="scoped">
	.nav4 {
		height: 1100px;
		position: relative;
		clear: both;
	}
	.liuff {
		background: url("../../assets/all/bg.jpeg") repeat no-repeat;
		background-size: 100%;
		clear: both;
		position: relative;
		height: 900px;
		opacity: 0.9;
		background-attachment: fixed;
		padding-top: 100px;
	
	}
</style>
