import { render, staticRenderFns } from "./p4.vue?vue&type=template&id=41477a10&scoped=true"
import script from "./p4.vue?vue&type=script&lang=js"
export * from "./p4.vue?vue&type=script&lang=js"
import style0 from "./p4.vue?vue&type=style&index=0&id=41477a10&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41477a10",
  null
  
)

export default component.exports