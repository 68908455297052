<template>
	<div style="margin: auto;padding: 30px 0;width:60%;"  >
		<div style="font-size: 23px;font-weight: 400;">云游中国</div>
			<div style="margin-top: -10px;"><img width="80px" src="../../assets/home/st1.png"></div>
		<el-row>
			<el-col :md="7" offset="2" style="margin-right:33px;">
			<div style="position:relative;text-align: center;">
			<a target="_blank" href="https://720yun.com/t/7cvkzwfyO89?scene_id=87382213">
				<img class="myimg"  src="../../assets/cloud/手机端/太原太山景区龙泉寺.jpg" style="width: 370px;height: 657px;" alt="">
				<div style="width: 170px;position:absolute;top:45%;margin: auto;text-align: center;color: #fff;font-weight: 600;font-size: 14px;left: 100px;">
					<img src="../../assets/cloud/手机端/iconl.png" width="15px" alt="">
					太原太山景区龙泉寺
					<img src="../../assets/cloud/手机端/icon.png" width="15px" alt="">
				</div>
				</a>
			</div>
			</el-col>
			<el-col :md="12">
			<div style="position:relative;text-align: center;">
			<a target="_blank" href="https://720yun.com/t/415je7sOeu1?scene_id=18012992">
				<img class="myimg" src="../../assets/cloud/手机端/常州天宁宝塔.jpg" width="400px" alt="">
				<div style="width: 170px;position:absolute;top:45%;margin: auto;text-align: center;color: #fff;font-weight: 600;font-size: 14px;left: 170px;">
					<img src="../../assets/cloud/手机端/iconl.png" width="15px" alt="">
					常州天宁宝塔
					<img src="../../assets/cloud/手机端/icon.png" width="15px" alt="">
				</div>
				</a>
			</div>
			<div style="margin-top:4px;position:relative;text-align: center;">
				<a target="_blank" href="https://720yun.com/t/84cjepyyuf4?scene_id=16190459">
				<img class="myimg" src="../../assets/cloud/手机端/杭州西湖雷峰塔.jpg" width="400px" alt="">
				<div style="width: 170px;position:absolute;top:45%;margin: auto;text-align: center;color: #fff;font-weight: 600;font-size: 14px;left: 170px;">
					<img src="../../assets/cloud/手机端/iconl.png" width="15px" alt="">
					杭州西湖雷峰塔
					<img src="../../assets/cloud/手机端/icon.png" width="15px" alt="">
				</div>
				</a>
			</div>
			</el-col>
		</el-row>
		<el-row style="margin-top:5px;">
			<el-col :md="8" offset="2" style="margin-right:42px;">
			<div  style="margin-top:4px;position:relative;text-align: center;">
			<a target="_blank" href="https://720yun.com/t/69vknmi777q?scene_id=31845689">
				<img class="myimg" src="../../assets/cloud/手机端/广州大佛寺.jpg" width="370px" alt="">
				<div style="width: 170px;position:absolute;top:45%;margin: auto;text-align: center;color: #fff;font-weight: 600;font-size: 14px;left: 100px;">
					<img src="../../assets/cloud/手机端/iconl.png" width="15px" alt="">
					广州大佛寺
					<img src="../../assets/cloud/手机端/icon.png" width="15px" alt="">
				</div>
				</a>
			</div>
			<div  style="margin-top:4px;position:relative;text-align: center;">
			<a target="_blank" href="https://720yun.com/t/98429usdmnw?scene_id=3617810">
				<img class="myimg" src="../../assets/cloud/手机端/太姥山景区一片瓦禅寺.jpg" width="370px" alt="">
				<div style="width: 200px;position:absolute;top:45%;margin: auto;text-align: center;color: #fff;font-weight: 600;font-size: 14px;left: 100px;">
					<img src="../../assets/cloud/手机端/iconl.png" width="15px" alt="">
					太姥山景区一片瓦禅寺
					<img src="../../assets/cloud/手机端/icon.png" width="15px" alt="">
				</div>
				</a>
			</div>
			</el-col>
			<el-col :md="7">
			<div  style="margin-top:4px;position:relative;text-align: center;">
				<a target="_blank" href="https://720yun.com/t/0e2j55kwew6?scene_id=10662578">
				<img class="myimg" src="../../assets/cloud/手机端/绍兴兜率天宫.jpg" style="width: 400px;height: 613px;" alt="">
				<div style="width: 170px;position:absolute;top:45%;margin: auto;text-align: center;color: #fff;font-weight: 600;font-size: 14px;left: 120px;">
					<img src="../../assets/cloud/手机端/iconl.png" width="15px" alt="">
					绍兴兜率天宫
					<img src="../../assets/cloud/手机端/icon.png" width="15px" alt="">
				</div>
				</a>
			</div>
			</el-col>
		</el-row>
		<el-row style="margin-top:5px;">
			<el-col :md="7" offset="2" style="margin-right:33px;">
			<div  style="margin-top:4px;position:relative;text-align: center;">
			<a target="_blank" href="https://720yun.com/t/c1vks78lO79?scene_id=47573006">
					<img class="myimg" src="../../assets/cloud/手机端/隋唐洛阳城.jpg" style="width: 370px;height: 657px;" alt="">
					<div style="width: 170px;position:absolute;top:45%;margin: auto;text-align: center;color: #fff;font-weight: 600;font-size: 14px;left: 110px;">
						<img src="../../assets/cloud/手机端/iconl.png" width="15px" alt="">
						隋唐洛阳城
						<img src="../../assets/cloud/手机端/icon.png" width="15px" alt="">
					</div>
			</a>
			</div>
			</el-col>
			<el-col :md="12">
			<div  style="margin-top:4px;position:relative;text-align: center;">
			<a target="_blank" href="https://720yun.com/t/17vkiyilgql?scene_id=34395946">
				<img class="myimg" src="../../assets/cloud/手机端/珠穆朗玛峰关帝庙.jpg" width="400px" alt="">
				<div style="width: 170px;position:absolute;top:45%;margin: auto;text-align: center;color: #fff;font-weight: 600;font-size: 14px;left: 170px;">
					<img src="../../assets/cloud/手机端/iconl.png" width="15px" alt="">
					珠穆朗玛峰关帝庙
					<img src="../../assets/cloud/手机端/icon.png" width="15px" alt="">
				</div></a>
			</div>
			<div  style="margin-top:4px;position:relative;text-align: center;">
			<a target="_blank" href="https://720yun.com/t/40b2dxauqbf?scene_id=1244182">
				<img class="myimg" src="../../assets/cloud/手机端/大洪山金顶.jpg" width="400px" alt="">
				<div style="width: 170px;position:absolute;top:45%;margin: auto;text-align: center;color: #fff;font-weight: 600;font-size: 14px;left: 170px;">
					<img src="../../assets/cloud/手机端/iconl.png" width="15px" alt="">
					大洪山金顶
					<img src="../../assets/cloud/手机端/icon.png" width="15px" alt="">
				</div></a>
			</div>
			</el-col>
		</el-row>

	</div>
</template>

<script>
	export default {
		data() {
			return {

				abouttitle: sessionStorage.abouttitle,
				aboutcontent1: sessionStorage.aboutcontent1,
				aboutcontent2: sessionStorage.aboutcontent2,
			}
		},
		mounted() {

		},
		watch: {
			abouttitle(a, b) {
				console.log(a, b)
			}

		}
	}
</script>

<style >
.myimg {
   filter: brightness(60%);
}
</style>
